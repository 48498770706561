import React from 'react'
import { useTheme } from 'styled-components'
// import Heading from '../../components/Heading/Heading'
// import getThemeValue from '../../util/getThemeValue'
import { ModalBody, ModalHeader, ModalTitle, ModalContainer, ModalCloseButton, ModalBackButton, Header } from './styles'
import { ModalProps } from './types'
import { Text } from '../../components/Text'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  headerBackground = 'transparent',
  minWidth = '320px',
  maxWidth = '434px',
  textScale,
  noHeader = false,
  ...props
}) => {
  const theme = useTheme()
  return (
    <ModalContainer minWidth={minWidth} maxWidth={maxWidth} {...props}>
      {/* <Header>
        <img src="/images/home-meme/swap-header.svg" alt="" />
      </Header> */}
      {noHeader ? null : (
        <ModalHeader>
          <ModalTitle>
            {onBack && <ModalBackButton onBack={onBack} />}
            <Text fontSize={['20px']} color="var(--color-black)">
              {title}
            </Text>
          </ModalTitle>
          {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
        </ModalHeader>
      )}

      <ModalBody p={bodyPadding}>{children}</ModalBody>
    </ModalContainer>
  )
}

export default Modal
