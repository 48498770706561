import styled from 'styled-components'

const Loading = styled.div`
  border: 4px solid #fcb0b5;
  border-radius: 50%;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fcb0b5;

  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Loading
